import React from 'react'

import { classnames } from 'begonya/functions'

import { useApp } from '@/core/contexts/app'

import { Column, Container, Row } from '@/components/base/gridview'
import Anchor from '@/components/base/anchor'
import Image from '@/components/base/image'

import { FooterProps } from './types'

const Footer: React.FunctionComponent<FooterProps> = ({
  logo,
  about,
  navigation = [],
}) => {
  const app = useApp()

  return (
    <footer className="footer">
      <Container size="medium">
        <div className="footer-logo">
          <Row xs={{ justify: 'center' }}>
            <Column auto={true}>
              <Anchor href={app.settings.homepage}>
                <Image
                  fluid
                  {...(logo?.src ? logo : app.theme.logo)}
                  alt={app.page.title}
                />
              </Anchor>
            </Column>
          </Row>
        </div>

        <div className="footer-about">
          <Row>
            <Column xs={{ size: 12 }}>
              {about && <div dangerouslySetInnerHTML={{ __html: about }} />}
            </Column>
          </Row>
        </div>

        <div className="footer-menu">
          <Row>
            <Column xs={{ size: 12 }}>
              <ul>
                {navigation.map((menuItem, index) => (
                  <li
                    key={index}
                    className={classnames(
                      menuItem.className,
                      menuItem.children.length && 'hasSubMenu'
                    )}
                  >
                    <div>
                      {menuItem.children.length ? (
                        <Anchor onClick={(e) => e.preventDefault()}>
                          {menuItem.title}
                        </Anchor>
                      ) : (
                        <Anchor
                          href={menuItem.href}
                          dangerouslySetInnerHTML={{
                            __html: menuItem.title,
                          }}
                        />
                      )}
                      {menuItem.children.length ? (
                        <ul className="sub-menu">
                          {menuItem.children.map((subItem, subIndex) => (
                            <li key={subIndex}>
                              <Anchor href={subItem.href}>
                                {subItem.title}
                              </Anchor>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <></>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </Column>
          </Row>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
